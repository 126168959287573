<template>
  <div>
    <component
      :is="qmoduleBase.getQcomponent(toPascalCase(qcomponent.componentType))"
      v-for="(qcomponent, qcomponentIndex) in route.meta.menu.module
        .qcomponents"
      :key="'component-index-' + qcomponentIndex"
      v-bind="qcomponent"
      :module="route.meta.menu.module"
      :component-id="qmoduleBase.moduleId + '-' + qcomponentIndex"
      :module-id="qmoduleBase.moduleId"
      :module-stack="qmoduleBase.moduleStack"
      style="margin-bottom: 20px"
    />
  </div>
</template>
<script setup>
import { useQmoduleBase } from "~/composables/useQmoduleBase";
import { useQmoduleProps } from "~/composables/useQmoduleProps";

const props = defineProps(useQmoduleProps());
const qmoduleBase = useQmoduleBase(props);
const route = useRoute();
</script>
